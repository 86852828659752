.about {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.category-container {
    position: relative;
    flex: 100%;
    margin-top: 1em;
    padding: 0;
    width: 100%;
    height: auto;
    color: #223940;
    line-height: 0.6;
}

.about-content-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    /*Change to row-reverse for bigger screens */
    flex: 100%;
    margin-top: 20px;
}

.about-photo-container, .about-text-container {
    position: relative;
    width: 100%;
    height: auto;
    flex: 100%;
    /*Change to 50% for bigger screens */
    margin: 0.5em;
}

.category-title {
    position: relative;
    width: 100%;
    font-size: 1em;
}

#horizontal-line {
    position: relative;
    height: 1px;
    width: 6em;
    background-color: #A4978E;
}

.about-text {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.personal-description-title {
    width: 100%;
    font-size: 1.5rem;
    color: #BE9063;
    line-height: 0.6;
}

.personal-description {
    color: #A4978E;
    font-size: 1rem;
}

.about-me-photo {
    position: relative;
    width: 100%;
    height: auto;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .about-content-container {
        flex-direction: row-reverse;
        /*Change to row-reverse for bigger screens */
    }
    .about-photo-container, .about-text-container {
        flex: 50%;
        /*Change to 50% for bigger screens */
    }

    .about-text-container {
        padding-left: 2em;
    }

        
    .personal-description {
        font-size: 0.7rem;
    }

}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .about-content-container {
        flex-direction: row-reverse;
        /*Change to row-reverse for bigger screens */
    }
    .about-photo-container, .about-text-container {
        flex: 50%;
        /*Change to 50% for bigger screens */
    }

    
    .about-text-container {
        padding-left: 1.5em;
    }

}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .about-content-container {
        flex-direction: column;
        /*Change to row-reverse for bigger screens */
    }
    .about-photo-container, .about-text-container {
        flex: 100%;
        /*Change to 50% for bigger screens */
    }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .about-content-container {
        flex-direction: row-reverse;
        /*Change to row-reverse for bigger screens */
    }
    .about-photo-container, .about-text-container {
        flex: 50%;
        /*Change to 50% for bigger screens */
    }
    
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .about-content-container {
        flex-direction: row-reverse;
        /*Change to row-reverse for bigger screens */
    }
    .about-photo-container, .about-text-container {
        flex: 50%;
        /*Change to 50% for bigger screens */
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}