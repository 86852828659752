.projects {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

/*Category aanduiding */

.category-container {
    position: relative;
    flex: 100%;
    margin-top: 1em;
    padding: 0;
    width: 100%;
    height: auto;
    color: #223940;
    line-height: 0.6;
}

.category-title {
    position: relative;
    width: 100%;
    font-size: 1em;
}

#horizontal-line {
    position: relative;
    height: 1px;
    width: 6em;
    background-color: #A4978E;
}

/*Eind Category aanduiding */

.project-content-container, .projects-content-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-top: 2em;
}

.project-container {
    --border-width: 0.1em;
    --border-color: #BE9063;
    --animation-speed: 1s;
    position: relative;
    width: 150px;
    height: 200px;
    background-color: transparent;
    border: var(--border-width) solid transparent;
    margin: 1em 1em;
    outline: none;
    cursor: pointer;
    transition: 0.6s;
}

.project-sample-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #040C0E;
    z-index: 1;
}

.project-head-image {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-filter: brightness(80%);
    filter: brightness(100%);
    border: 0.1em solid #040C0E;
    opacity: 0;
    animation-name: imageFade;
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    transition: 0.2s;
}

.project-title {
    position: absolute;
    text-align: center;
    text-transform: capitalize;
    font-size: 0.6rem;
    color: #BE9063;
    letter-spacing: 2px;
    top: 6vmin;
    left: -20px;
    opacity: 0;
    height: auto;
    padding: 0.2em 0.1em;
    background-color: #040C0E;
    animation-name: titleFly;
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    -webkit-box-shadow: 3px 4px 17px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 4px 17px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 4px 17px -4px rgba(0, 0, 0, 0.75);
}

.project-container:hover>.project-sample-container>.project-head-image {
    filter: brightness(80%);
}

.project-container:hover {
    transform: scale(1.03);
}

.project-container::before, .project-container::after {
    content: '';
    position: absolute;
    background-color: var(--border-color);
}

.project-container::before, .project-container::before {
    animation: rightBorder var(--animation-speed) forwards ease-in-out;
}

.project-container::after, .project-container::after {
    animation: leftBorder var(--animation-speed) forwards ease-in-out;
}

.project-container, .project-container {
    animation: borderColors var(--animation-speed) steps(1) forwards;
}

.projects-exit {
    animation-name: projectsExit;
    animation-duration: 0.6s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes projectsExit {
    0% {}
    100% {
        filter: brightness(0%);
        opacity: 0;
    }
}

@keyframes imageFade {
    0% {}
    100% {
        opacity: 0.9;
    }
}

@keyframes titleFly {
    0% {
        color: transparent;
        width: 0;
        border: 2px solid transparent;
    }
    10% {
        opacity: 0;
    }
    33% {
        border: 2px solid #BE9063;
        border-bottom-right-radius: 6px;
    }
    66% {
        width: 70%;
    }
    100% {
        width: 70%;
        border: 2px solid #BE9063;
        border-bottom-right-radius: 6px;
        opacity: 1;
        color: #BE9063;
    }
}

@keyframes leftBorder {
    0% {
        top: calc(var(--border-width) * -1);
        left: 50%;
        bottom: auto;
        right: auto;
        width: 0;
        height: var(--border-width);
    }
    33% {
        top: calc(var(--border-width) * -1);
        left: calc(var(--border-width) * -1);
        bottom: auto;
        right: auto;
        width: calc(var(--border-width) + 50%);
        height: var(--border-width);
    }
    66% {
        top: calc(var(--border-width) * -1);
        left: calc(var(--border-width) * -1);
        bottom: auto;
        right: auto;
        width: var(--border-width);
        height: calc((var(--border-width) * 2) + 100%);
    }
    100% {
        top: auto;
        left: calc(var(--border-width) * -1);
        bottom: calc(var(--border-width) * -1);
        right: auto;
        width: calc(var(--border-width) + 50%);
        height: calc((var(--border-width) * 2) + 100%);
    }
}

@keyframes rightBorder {
    0% {
        top: calc(var(--border-width) * -1);
        left: auto;
        bottom: auto;
        right: 50%;
        width: 0;
        height: var(--border-width);
    }
    33% {
        top: calc(var(--border-width) * -1);
        left: auto;
        bottom: auto;
        right: calc(var(--border-width) * -1);
        width: calc(var(--border-width) + 50%);
        height: var(--border-width);
    }
    66% {
        top: calc(var(--border-width) * -1);
        left: auto;
        bottom: auto;
        right: calc(var(--border-width) * -1);
        width: var(--border-width);
        height: calc((var(--border-width) * 2) + 100%);
    }
    100% {
        top: auto;
        left: auto;
        bottom: calc(var(--border-width) * -1);
        right: calc(var(--border-width) * -1);
        width: calc(var(--border-width) + 50%);
        height: calc((var(--border-width) * 2) + 100%);
    }
}

@keyframes borderColors {
    0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    33% {
        border-top-color: var(--border-color);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    66% {
        border-top-color: var(--border-color);
        border-right-color: var(--border-color);
        border-bottom-color: transparent;
        border-left-color: var(--border-color);
    }
    100% {
        border-top-color: var(--border-color);
        border-right-color: var(--border-color);
        border-bottom-color: var(--border-color);
        border-left-color: var(--border-color);
    }
}

.project-info-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: auto;
    margin: 1em;
    border: 1px solid transparent;
    border-right: none;
    opacity: 0;
    animation-name: projectFade;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.project-info-text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.project-info-title {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
    text-transform: uppercase;
    color: #BE9063;
}

.project-info-languages {
    width: 100%;
    font-size: 0.6rem;
    padding: 0px 16px;
    letter-spacing: 2px;
    color: #A4978E;
    text-align: center;
}

.project-info-text, .project-technology-text {
    padding: 0.25em 1.6em;
    font-size: 1rem;
    color: #A4978E;
}

.project-info-buttons {
    width: 100%;
    align-self: center;
    justify-content: center;
    display: flex;
    position: relative;
}

.project-btn {
    font-size: 1rem;
    padding: 0.2em 1.6em;
    text-align: center;
    text-decoration: none;
    color: #BE9063;
    border: 1px solid #BE9063;
    background-color: #040C0E;
    border-bottom-right-radius: 6px;
    margin: 1em 1.2em;
    cursor: pointer;
    transition: 0.2s;
}

.project-btn:hover {
    border: 1px solid transparent;
    border-bottom: 1px solid #BE9063;
    border-bottom-right-radius: 0px;
}

.project-btn:active, .project-btn:focus {
    outline: none;
}

.project-images-container {
    position: relative;
    width: 100%;
    height: auto;
    display: none;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    padding: 1em 0.25em;
    border-top: 1px solid #BE9063;
}

.first-column {
    flex: 33%;
    padding-right: 6px;
}

.second-column {
    flex: 33%;
    padding: 0px 6px;
}

.third-column {
    flex: 33%;
    padding: 0px 6px;
}

.project-image {
    margin: 6px 0px;
    vertical-align: middle;
    width: 100%;
    opacity: 0;
    animation-name: projectFade;
    animation-duration: 1.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

@keyframes projectFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes projectBtnAni {
    0% {
        border: 1px solid #BE9063;
        border-bottom-right-radius: 6px;
    }
    100% {
        border: 1px solid transparent;
        border-bottom: 1px solid #BE9063;
        border-bottom-right-radius: 0px;
    }
}

.project-images-carousel-mobile {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 0.5em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid #BE9063;
}

.project-image-mobile {
    padding: 0.2em;
    vertical-align: middle;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    animation-name: projectFade;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .project-container {
        width: 270px;
        height: 350px;
    }
    .projects-content-container {
        align-items: unset;
        justify-content: center;
        justify-content: space-around;
    }
    .project-content-container {
        margin-top: 0;
    }
    .project-title {
        font-size: 0.5rem;
    }
    .project-info-text-container {
        padding: 0.5em;
        width: 30%;
    }
    .project-info-container {
        justify-content: center;
    }
    .project-images-carousel-mobile {
        display: none;
    }
    .project-images-container {
        display: flex;
        width: 70%;
        margin-top: 0;
        border: none;
        padding: 1em;
    }
    .project-info-title {
        font-size: 1.3rem;
        width: 30%;
    }
    .project-info-languages {
        font-size: 0.4rem;
    }
    .project-info-text, .project-technology-text {
        font-size: 0.7rem;
        padding: 0.25em 1.6em;
    }
    .project-info-buttons {
        justify-content: center;
        justify-content: space-evenly;
    }
    .project-btn {
        font-size: 0.7rem;
        width: 25%;
        padding: 0.2em 2.6em;
    }
}

/*Ipad Pro Only*/

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .projects {
        align-items: flex-start;
    }
    .project-title {
        font-size: 0.5rem;
    }
    .project-info-text-container {
        padding: 1em;
        width: 100%;
        border: none;
    }
    .project-images-container {
        display: flex;
        width: 90%;
        border-top: 1px solid #BE9063;
    }
    .project-info-buttons {
        justify-content: center;
        justify-content: space-evenly;
    }
    .project-btn {
        font-size: 0.7rem;
        width: 25%;
        padding: 0.2em 2.6em;
    }
    .project-content-container, .projects-content-container {
        margin-top: 0em !important;
    }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
    .project-container {
        width: 230px;
        height: 300px;
    }
    .project-images-carousel-mobile {
        display: none;
    }
    .project-images-container {
        display: flex;
        width: 80%;
    }
    .project-info-container {
        justify-content: center;
    }
    .project-info-buttons {
        justify-content: center;
        justify-content: space-evenly;
    }
    .project-btn {
        font-size: 0.7rem;
        width: 20%;
        padding: 0.4em 2.6em;
    }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
    .project-container {
        width: 220px;
        height: 290px;
    }
    .project-images-carousel-mobile {
        display: block;
        width: 70%;
    }
    .project-images-container {
        display: none;
    }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1025px) and (orientation: landscape) {
    .project-container {
        width: 200px;
        height: 270px;
    }
    .projects-content-container {
        align-items: unset;
    }
    .project-images-carousel-mobile {
        display: none;
    }
    .project-images-container {
        display: flex;
    }
    .project-info-buttons {
        justify-content: center;
        justify-content: space-evenly;
    }
    .project-btn {
        width: 20%;
    }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    .project-content-container {
        margin-top: 1em;
    }
    .project-container {
        width: 150px;
        height: 200px;
    }
    .project-images-carousel-mobile {
        width: 40%;
        border: none;
        padding-top: 0;
    }
    .project-info-text-container {
        width: 50%;
    }
    .project-info-buttons {
        justify-content: center;
        justify-content: space-evenly;
    }
    .project-btn {
        width: 20%;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
    .project-container {
        width: 150px;
        height: 200px;
    }
}