.mainpage{
    width: 100%;
    height: auto;
    min-height: 100vh;   
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.category-container {
    position: relative;
    flex: 100%;  
    margin-top: 1em;
    padding: 0;
    width: 100%;
    height: auto;  
    color: #223940;
    line-height: 0.6;
}

.mainpage-content-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column; /*Change to row-reverse for bigger screens */
    flex: 100%;
}

.profile-photo-container, 
.intro-text-container {    
    position: relative;
    width: 100%;
    height: auto;
    flex: 100%; /*Change to 50% for bigger screens */    
    margin: 0.5em;
}
  
.category-title {
    position: relative;
    width: 100%;
    font-size: 1em;
}
  
#horizontal-line{
    position: relative;
    height: 1px;
    width: 6em;
    background-color: #A4978E;
}

.intro-text{
    height: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.4rem;
    text-align: center;
    color: #A4978E;    
    line-height: 0.6;
}
.welcome-text{
    color: #BE9063;
    font-size: 1.5rem;    
    line-height: 2.5;
    align-self: flex-start;
}

.function-description{
    width: 100%;
    font-size: 1rem;
    color: #223940;    
}

.profile-photo{
    position: relative;
    width: 100%;
    height: auto;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .mainpage-content-container{
        flex-direction: row-reverse; /*Change to row-reverse for bigger screens */
    }
    
    .profile-photo-container, 
    .intro-text-container {    
        flex: 50%; /*Change to 50% for bigger screens */    
    }

    
.intro-text{

    font-size: 2.3rem;
}
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    .mainpage-content-container{
        flex-direction: row-reverse; /*Change to row-reverse for bigger screens */
    }
    
    .profile-photo-container, 
    .intro-text-container {    
        flex: 50%; /*Change to 50% for bigger screens */    
    }

  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
 
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .mainpage-content-container{
        flex-direction: row-reverse; /*Change to row-reverse for bigger screens */
    }
    
    .profile-photo-container, 
    .intro-text-container {    
        flex: 50%; /*Change to 50% for bigger screens */    
    }
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    .mainpage-content-container{
        flex-direction: row-reverse; /*Change to row-reverse for bigger screens */
    }
    
    .profile-photo-container, 
    .intro-text-container {    
        flex: 50%; /*Change to 50% for bigger screens */    
    }

    .intro-text{
        font-size: 2.3rem;
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
  
    
  }
