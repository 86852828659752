:root {
    --flex-direction: row;
    /*deze aanpassen naar 'column' groter scherm */
}

.navbar {
    position: relative;
    height: 100%;
}

.nav-menu-btn-container {
    position: fixed;
    z-index: 10;
    display: flex;
    align-items: center;
    right: 0;
    width: 10vw;
    height: 11vh;
}

.nav-menu-btn {
    position: relative;
    width: 22px;
    height: auto;
    display: block;
    padding-top: 0.2em;
    background-color: transparent;
    border: 1px solid transparent;
    color: #BE9063;
    font-size: 1em;
    transition: 0.2s;
}

.navigation-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-direction: var(--flex-direction);
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 11vh;
    /*set to 10vh for portrait mobile */
    background-color: #040C0E;
    border-bottom: 1px solid #BE9063;
    /*bottom-right big screens */
    -webkit-box-shadow: 0px -10px 16px 4px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px -10px 16px 4px rgba(255, 255, 255, 1);
    box-shadow: 0px -10px 16px 4px rgba(255, 255, 255, 1);
}

.nav-in {
    visibility: visible;
    opacity: 1;
    transition: visibility 600ms, opacity 600ms;
    transition-delay: 0.2s;
}

.nav-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 400ms, opacity 600ms;
    transition-delay: 0.3s;
}

.nav-btn-container, .social-container {
    width: 75vw;
    height: 100%;
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-direction: var(--flex-direction);
    list-style-type: none;
    position: relative;
    margin: 0;
    padding: 0;
}

.social-container {
    display: none;
}

.nav-item {
    position: relative;
    width: auto;
    height: auto;
}

.nav-btn {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    color: #525B56;
    padding: 0;
    cursor: pointer;
}

.nav-btn:active, .nav-btn:focus {
    outline: none;
}

.nav-btn:hover {
    color: #BE9063;
}

.nav-active {
    color: #BE9063;
}

.nav-icon {
    position: relative;
}

.nav-text {
    position: absolute;
    z-index: 5;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: left;
    width: 160px;
    height: 50px;
    line-height: 50px;
    /*change for bigger screen */
    top: 0;
    left: 6vw;
    display: none;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
}

.nav-social-link {
    width: 100%;
}

.nav-social-link:hover>.nav-social-icon {
    color: #223940;
}

.nav-social-icon {
    color: #132226;
    margin: 0.5vmin;
}

@keyframes btnInLeft {
    0% {
        left: -4vmin
    }
    100% {
        left: 0vmin;
    }
}

@keyframes btnOutLeft {
    0% {
        left: 0vmin
    }
    100% {
        left: -4vmin;
    }
}

.nav-logo-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 15vw;
    height: 100%;
}

.show {
    display: block !important;
}

.logo-container {
    width: 40px;
    height: 40px;
    display: none;
}

.logo-stroke {
    fill: none;
    stroke: #BE9063;
    stroke-width: 8;
    opacity: 0;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: 200;
    stroke-opacity: 1;
    animation-name: intro;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

#bUpper {
    animation-delay: 0.3s;
}

#bLower {
    animation-delay: 0.7s;
}

#nLeft {
    animation-delay: 0.5s;
}

#nRight {
    animation-delay: 0.3s;
}

@keyframes intro {
    0% {
        opacity: 0;
        stroke-dashoffset: 200;
    }
    100% {
        opacity: 1;
        stroke-dashoffset: 0;
    }
}

.nav-menu-btn input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

/*
 * Just a quick hamburger
 */

.nav-menu-btn span {
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 6px;
    position: relative;
    border-radius: 3px;
    background-color: #BE9063;
    z-index: 1;
    transition: transform 0.7s cubic-bezier(0.77, 0.2, 0.05, 1.0), background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

.nav-menu-btn #two {
    margin-left: 4px;
    width: 80%;
}

.nav-menu-btn #one {
    transform-origin: -5% 0%;
}

.nav-menu-btn #three {
    transform-origin: -10% 80%;
}

.nav-menu-btn input:checked~span {
    background-color: #525B56;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */

.nav-menu-btn input:checked~#one {
    opacity: 1;
    transform: rotate(45deg);
}

/*
 * But let's hide the middle one.
 */

.nav-menu-btn input:checked~#two {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */

.nav-menu-btn input:checked~#three {
    transform: rotate(-45deg)
}

.spacer-mobile-btn {
    display: block;
    position: relative;
    width: 10vw;
    height: 100%;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    :root {
        --flex-direction: column;
    }
    .navigation-bar {
        width: 4vw;
        height: 100vh !important;
        flex-direction: column;
        background-color: #040C0E;
        border-right: 1px solid #BE9063;
        border-bottom: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
    .nav-btn:hover>.nav-text {
        display: block;
    }
    .nav-menu-btn-container {
        width: 4vw !important;
        height: 10vh;
        left: 0;
        z-index: 8;
        justify-content: center;
    }
    .nav-menu-btn {
        width: 22px !important;
    }
    .nav-menu-btn span {
        width: 100%;
        height: 2px !important;
        margin-bottom: 6px !important;
        transition-delay: 0.5s;
        transition-duration: 1s;
        transition-timing-function: ease;
    }
    .nav-menu-btn #two {
        margin-left: 0px !important;
    }
    .nav-menu-btn input:checked~span {
        background-color: #BE9063;
    }
    .nav-menu-btn input:checked~#one {
        opacity: 0;
        transform: translateX(-50px);
    }
    .nav-menu-btn input:checked~#two {
        opacity: 0;
        transform: translateX(-50px);
    }
    .nav-menu-btn input:checked~#three {
        opacity: 0;
        transform: translateX(-50px);
    }
    .nav-logo-container {
        position: relative;
        align-items: center;
        display: flex;
        width: auto;
        height: 10%;
    }
    .logo-container {
        width: 50px;
        height: 50px;
    }
    .nav-btn-container {
        width: 100%;
        height: 75%;
        flex-direction: column;
        justify-content: center;
    }
    .social-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 15%;
    }
    .nav-text {
        left: 4vw;
        /*change for bigger screen width navbar */
    }
    .nav-btn {
        width: 50px;
        height: 50px;
        font-size: 0.8em;
        margin-top: 1.5em;
    }
    .spacer-mobile-btn {
        display: none;
    }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
    :root {
        --flex-direction: column;
    }
    .navigation-bar {
        width: 6vw;
        height: 100vh !important;
        flex-direction: column;
        background-color: #040C0E;
        border-right: 1px solid #BE9063;
        border-bottom: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none
    }
    .nav-btn:hover>.nav-text {
        display: block;
    }
    .nav-menu-btn-container {
        width: 6vw !important;
        height: 15vh;
        left: 0;
        z-index: 8;
        justify-content: center;
    }
    .nav-menu-btn {
        width: 22px !important;
    }
    .nav-menu-btn span {
        width: 100%;
        height: 2px !important;
        margin-bottom: 6px !important;
        transition-delay: 0.5s;
        transition-duration: 1s;
        transition-timing-function: ease;
    }
    .nav-menu-btn #two {
        margin-left: 0px !important;
    }
    .nav-menu-btn input:checked~span {
        background-color: #BE9063;
    }
    .nav-menu-btn input:checked~#one {
        opacity: 0;
        transform: translateX(-50px);
    }
    .nav-menu-btn input:checked~#two {
        opacity: 0;
        transform: translateX(-50px);
    }
    .nav-menu-btn input:checked~#three {
        opacity: 0;
        transform: translateX(-50px);
    }
    .nav-logo-container {
        position: relative;
        align-items: center;
        display: flex;
        width: auto;
        height: 15%;
    }
    .logo-container {
        width: 50px !important;
        height: 50px !important;
    }
    .nav-btn-container {
        width: 100%;
        height: 70%;
        flex-direction: column;
        justify-content: center;
    }
    .social-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 15%;
    }
    .nav-btn {
        width: 50px;
        height: 50px;
        font-size: 1.0em !important;
        margin-top: 1em;
    }
    .nav-text {
        left: 6vw;
    }
    .spacer-mobile-btn {
        display: none;
    }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
    .nav-menu-btn-container {
        width: 10vw;
        height: 10vh;
    }
    .nav-menu-btn {
        width: 28px;
    }
    .nav-menu-btn span {
        width: 100%;
        height: 2px;
        margin-bottom: 8px;
    }
    .nav-logo-container {
        justify-content: center;
    }
    .logo-container {
        width: 50px;
        height: 50px;
    }
    .nav-btn-container, .social-container {
        justify-content: center;
    }
    .nav-btn {
        width: 50px;
        height: 50px;
        font-size: 1.1em;
        margin-left: 1em;
        margin-right: 1em;
    }
    .navigation-bar {
        height: 10vh;
    }
}

/*Ipad Pro Only (Portrait) */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .navigation-bar {
        border-width: 2px;
    }
    .nav-menu-btn-container {
        width: 10vw;
        height: 11vh;
    }
    .nav-menu-btn {
        width: 35px;
    }
    .nav-menu-btn #two {
        margin-left: 6px;
    }
    .nav-menu-btn span {
        height: 3px;
        margin-bottom: 10px;
    }
    .nav-logo-container {
        justify-content: center;
    }
    .logo-container {
        width: 75px;
        height: 75px;
    }
    .nav-btn-container, .social-container {
        justify-content: center;
    }
    .nav-btn {
        font-size: 1.1em;
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
    .navigation-bar {
        height: 11vh;
    }
}

/*Ipad Pro Only (Landscape)*/

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .nav-menu-btn #two {
        margin-left: 5px;
    }
    .navigation-bar {
        width: 8vw;
    }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .nav-menu-btn-container {
        height: 13vh;
    }
    .navigation-bar {
        height: 13vh;
    }
    .nav-menu-btn {
        width: 28px;
    }
    .nav-menu-btn span {
        width: 100%;
        height: 2px;
        margin-bottom: 8px;
    }
    .nav-logo-container {
        justify-content: center;
    }
    .logo-container {
        width: 50px;
        height: 50px;
    }
    .nav-btn-container, .social-container {
        justify-content: center;
    }
    .nav-btn {
        font-size: 1.1em;
        margin-left: 1em;
        margin-right: 1em;
    }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    .nav-menu-btn-container {
        height: 18vh;
    }
    .nav-logo-container {
        justify-content: center;
    }
    .nav-menu-btn {
        width: 22px;
    }
    .navigation-bar {
        height: 18vmin;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {}