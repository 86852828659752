.contact {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
}

/*Category aanduiding */

.category-container {
    position: relative;
    flex: 100%;
    margin-top: 1em;
    padding: 0;
    width: 100%;
    height: auto;
    color: #223940;
    line-height: 0.6;
}

.category-title {
    position: relative;
    width: 100%;
    font-size: 1em;
}

#horizontal-line {
    position: relative;
    height: 1px;
    width: 6em;
    background-color: #A4978E;
}

/*Eind Category aanduiding */

.contact-content-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    flex: 100%;
}

.contact-text-container, .contact-form-container {
    position: relative;
    display: flex;
    flex: 100%;
    flex-wrap: nowrap;  /*stond op wrap met form erbij*/
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 2em;
    padding: 0.6em;
}

.contact-form-container form {
    width: 100%;
    padding: 1em;
    font-size: 1rem;
}

.title-form {
    width: 100%;
    position: relative;
    font-size: 1.5rem;
    color: #BE9063;
    text-align: left;
}

.contact-text {
    position: relative;
    /* margin-left: auto;            
    margin-right: auto;        voor form erbij      */
    width: 100%;  /*verwijderen als form erbij is*/
    margin-top: 0;
    font-size: 1rem;
    color: #A4978E;
    line-height: 1.2;
}

.contact-title-text {
    color: #BE9063;
    font-size: 1.5rem;
}

.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1em;
}

.contact-icon-text {
    position: relative;
    display: none;
    /*deze voor grotere schermen*/
    opacity: 0;
    color: #BE9063;
    font-size: 0.4em;
    letter-spacing: 2px;
    padding: 0;
    margin: 0em;
    margin-top: 0.8em;
    text-transform: uppercase;
    transition: opacity 0.3s ease;
}

.contact-info a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em auto;
    /*deze 1em 2em  met form erbij*/
    text-decoration: none;
}

.contact-icon {
    color: #BE9063;
    font-size: 1.5em;
}

.contact-info a:hover>.contact-icon-text {
    opacity: 1;
}

.contact-form {
    position: relative;
    width: 100%;
    height: 100%;
    color: #BE9063;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.form-group input {
    position: relative;
    width: 100%;
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
}

.form-group label {
    display: block;
}

.form-group textarea {
    position: relative;
    width: 100%;
    height: 150px;
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    resize: vertical;
}

.form-control {
    height: calc(1em + 0.75rem + 2px);
    padding: 0.3rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #223940;
    background-color: #A4978E;
    background-clip: padding-box;
    border: 1px solid #A4978E;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    ;
}

.form-control::placeholder {
    font-family: var(--font);
    color: #223940;
}

.form-control:active, .form-control:focus {
    background-color: rgb(197, 192, 188);
    border: 1px solid rgb(134, 120, 110);
}

.form-control:hover {
    background-color: rgb(197, 192, 188);
}

.form-btn {
    float: right;
    padding: 0.4em 2em;
    font-size: 1rem;
    color: #BE9063;
    border: 1px solid #BE9063;
    background-color: #040C0E;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    margin: 1em auto;
    transition: 0.2s;
}

.form-btn:hover {
    border: 1px solid transparent;
    border-bottom: 1px solid #BE9063;
    border-bottom-right-radius: 0px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .contact-content-container {
        flex-direction: row;
        /*Change to row for bigger screens */
    }
    .contact-text-container, .contact-form-container {
        flex: 50%;
        padding: 0.8em;
    }
    .contact-text-container {
        padding-left: 1.5em;
    }
    .title-form {
        width: 100%;
        position: relative;
        font-size: 1.3rem;
        color: #BE9063;
        text-align: left;
    }
    .contact-title-text {
        color: #BE9063;
        font-size: 1.3rem;
    }
    .contact-text {
        font-size: 0.7rem;
    }
    .contact-form-container form {
        font-size: 0.7rem;
    }
    .form-control {
        font-size: 0.5rem;
    }
    .form-group input {
        margin-bottom: 2em;
    }
    .form-btn {
        font-size: 0.7rem;
    }
    .contact-info {
        justify-content: flex-start;
    }
    .contact-icon-text {
        display: block;
    }
    .contact-info a {
        margin: 1em 2em;
    }
    .contact-text {
        width: 60%;  /*verwijderen als form erbij is*/
    
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .contact-content-container {
        flex-direction: row;
        /*Change to row for bigger screens */
    }
    .contact-text-container, .contact-form-container {
        flex: 50%;
    }
    .form-btn {
        font-size: 0.8rem;
    }
    .contact-info {
        justify-content: flex-start;
    }
    .contact-info a {
        margin: 1em 2em;
    }
    .contact-icon-text {
        display: block;
    }

    .contact-text {
        width: 60%;  /*verwijderen als form erbij is*/
    
    }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
   

}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .contact-content-container {
        flex-direction: row;
        /*Change to row for bigger screens */
    }
    .contact-text-container, .contact-form-container {
        flex: 50%;
    }
    .contact-info {
        justify-content: flex-start;
    }
    .contact-info a {
        margin: 1em 2em;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .contact-content-container {
        flex-direction: row;
        /*Change to row for bigger screens */
    }
    .contact-text-container, .contact-form-container {
        flex: 50%;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}