.footer-container{
    width: 100%;
    height: auto;
    font-size: 0.8rem;
    color: #A4978E;
    padding: 0.4em;
    text-align: center;
}

.footer-link-container{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: auto;
    padding-bottom: 0.5em;
}

.footer-link{
    color: #A4978E;
    text-transform: uppercase;
    /* letter-spacing: 2px; */
    text-decoration: none;
}



@media (min-width: 1281px) {
    .footer-container {
        font-size: 0.6rem;
        text-align: right;
    }

    .footer-link-container{
        display: none;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .footer-container {        
        font-size: 0.6rem;
        text-align: right;
    }

    .footer-link-container{
        display: none;
    }
}