* {  
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

html {
  font-size: calc(10px + 2vmin);
  height: 100%;
}

.App {
  background-color: #040C0E;  
  height: 100%;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #525B56;
}

.content-container {
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}




/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1292px) {
    
  html{
    font-size: calc(10px + 2vmin);
  }  
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1292px) {
  
  html{
    font-size: calc(6px + 2vmin);
  }  
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023.98px) {
  
  html{
    font-size: calc(6px + 2vmin);
  }  
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  html{
    font-size: calc(6px + 1.5vmin);
  }  
  
.content-container {
  width: 80%;
}
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  html{
    font-size: calc(6px + 2vmin);
  }  
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  html{
    font-size: calc(6px + 2vmin);
  }  
  
  
}
