body {
  --font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  font-family: "Segoe UI", "Helvetica Neue", sans-serif;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* width */

::-webkit-scrollbar {
  position: absolute;
  display: block;
  z-index: 6;
  /* width: 4px; */
  width: 0px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #040C0E;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #223940;
}